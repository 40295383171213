import './App.css';

function App() {
  return (
    <div>
        <header>
            Welcome
        </header>
        <p>
            Hi There!
        </p>
    </div>
  );
}

export default App;
